
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { BadInput } from '../_common/bad-input';
import { NotFoundError } from '../_common/not-found-error';
import { ForbiddenError } from '../_common/forbidden-error';
import { AppError } from '../_common/app-error';

@Injectable()
export class DataService {
  httpClient: HttpClient;
  constructor(@Inject('API_BASE_URL') private url: string, @Inject(HttpClient) httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  getAll(filter) {
    return this.httpClient.get(this.url + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError))
  }

  get(id) {
    return this.httpClient.get(this.url + '/' + id)
      .pipe(catchError(this.handleError))
  }

  create(resource) {
    return this.httpClient.post(this.url, resource)
      .pipe(catchError(this.handleError))
  }

  update(id, resource) {
    return this.httpClient.put(this.url + '/' + id, resource)
      .pipe(catchError(this.handleError))
  }

  delete(id) {
    return this.httpClient.delete(this.url + '/' + id)
      .pipe(catchError(this.handleError))
  }

  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }

    return parts.join('&');
  }

  extractData(res: Response) {
    let body = res;
    return body || {};
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status == 400)
      throwError(new BadInput(errorResponse.error));
    else if (errorResponse.status === 404)
      throwError(new NotFoundError());
    else if (errorResponse.status == 401)
      throwError(new ForbiddenError());
    else
      throwError(new AppError(errorResponse));

    return EMPTY;
  }
}