import { IForgotPassword, IResetPassword, IConfirmEmail, ILogin, IRegister, IChangePassword } from '../../_models/account/user';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { Router } from "@angular/router";
import { DataService } from "./data.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService extends DataService {
  userToken: any;
  constructor(
    httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    @Inject('API_BASE_URL') private _baseUrl: string) {
    super(_baseUrl + 'api/account', httpClient);
  }

  login(login: ILogin) {
    return this.httpClient.post(this._baseUrl + 'api/account/login', login)
    .pipe(catchError(this.handleError))
  }

  confirmEmail(confirmEmail: IConfirmEmail) {
    return this.httpClient.post(this._baseUrl + 'api/account/confirmemail', confirmEmail)
    .pipe(catchError(this.handleError))
  }

  forgotPassword(forgotPassword: IForgotPassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/forgotpassword', forgotPassword)
    .pipe(catchError(this.handleError))
  }

  resetPassword(resetPassword: IResetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/resetpassword', resetPassword)
    .pipe(catchError(this.handleError))
  }


  register(register: IRegister) {
    return this.httpClient.post(this._baseUrl + 'api/account/register', register)
    .pipe(catchError(this.handleError))
  }

  changePassword(changePassword: IChangePassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/changepassword', changePassword)
    .pipe(catchError(this.handleError))
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/account/login']);
  }

  isLoggedIn() {
    return !this.jwtHelper.isTokenExpired();
  }

  get currentUser() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token);
  }

  get getToken() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return token;
  }

  get currentUserId() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  isInRole(role: string) {
    let roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (roles) {
      if (typeof roles == 'object' && roles.filter(x => x == role) == role) {
        return true;
      }
      else if (typeof roles == 'string' && roles == role) {
        return true;
      }
    }
    return false;
  }

}
