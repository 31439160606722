declare let toastr: any;
export class BadInput {
    constructor(public originalError?: any) {
        let modelStateErrors = '';
        for (const key in originalError) {
          if (originalError[key]) {
            modelStateErrors += originalError[key] + '\n';
          }
        }
        toastr.error(modelStateErrors);
    }
}