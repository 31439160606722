import { IMenuContentDrag } from '../../_models/cms/IMenu';
import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MenuContentService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/menu-contents', httpClient);
  }
  
  getAllMenuContents() {
    return this.httpClient.get(this.baseUrl + 'api/menu-contents/all')
    .pipe(catchError(this.handleError))
  }

  getMenuContentsByMenuId(menuId: number) {
    return this.httpClient.get(this.baseUrl + 'api/menu-contents/by-menu-id?menuId='+ menuId)
    .pipe(catchError(this.handleError))
  }

  
  postMenuContentOrder(menuContents: IMenuContentDrag[]) {
    return this.httpClient.post(this.baseUrl + 'api/menu-contents/update-order', menuContents)
    .pipe(catchError(this.handleError))
  }
}