import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CmsPageService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/cms-pages', httpClient);
  }

  getAllCmsPages() {
    return this.httpClient.get(this.baseUrl + 'api/cms-pages/all')
      .pipe(catchError(this.handleError))
  }

  getAllCmsPagesForAdmin() {
    return this.httpClient.get(this.baseUrl + 'api/cms-pages/admin')
      .pipe(catchError(this.handleError))
  }

  getBySlug(slug: string) {
    return this.httpClient.get(this.baseUrl + 'api/cms-pages/by-slug?slug=' + slug)
      .pipe(catchError(this.handleError))
  }

}