import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NewsItemService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/news-items', httpClient);
  }

  getFeatured() {
    return this.httpClient.get(this.baseUrl + 'api/news-items/featured')
      .pipe(catchError(this.handleError))
  }


}