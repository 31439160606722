import { IVideoGallery } from './../../../_models/cms/IVideoGallery';
import { VideoGalleryService } from './../../services/video-gallery.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css']
})
export class VideoGalleryComponent implements OnInit {
  slideConfig = { "lazyLoad": 'ondemand', "slidesToShow": 5, "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 2000 };
  videoGalleries: IVideoGallery[];
  gallery: IVideoGallery;
  url: string;

  constructor(
    private service: VideoGalleryService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getVideoGallery();
  }

  getVideoGallery() {
    this.service.getArchiveVideoGallery().subscribe((response: IVideoGallery[]) => {
      if (response) {
        this.videoGalleries = response;
      }
    })
  }

  youtube_parser(url: string) {
    var stepTwo = url.split('/');
    var videoId = stepTwo[stepTwo.length - 1];
    return videoId;
  }

  open(content, item) {
    this.gallery = item;
    this.modalService.open(content, { size: 'xl' });
  }

}
