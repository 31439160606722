import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sticky-notification',
  templateUrl: './sticky-notification.component.html',
  styleUrls: ['./sticky-notification.component.css']
})
export class StickyNotificationComponent implements OnInit {
  @Input() url: string;
  @Input() title: string = "New Industrial Policy 2020";
  @Input('show') isBtnShow: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.isBtnShow = false;
  }

  openBtn() {
    if (this.url)
      this.isBtnShow = true;
  }

  closeBtn() {
    this.isBtnShow = false;
  }

}
