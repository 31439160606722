import { Injectable } from '@angular/core';
declare let toastr: any;

@Injectable()
export class ToastrService {

  constructor() { }

  confirm(message: string, okCallback: () => any) {
    toastr.info(message, function(e) {
      if (e) {
        okCallback();
      } else {
      }
    });
  }

  success(message: string, timeOut: number = 5000) {
    toastr.success(message, {timeOut: timeOut});
  }

  error(message: string, timeOut: number = 5000) {
    toastr.error(message, {timeOut: timeOut});
  }

  warning(message: string, timeOut: number = 5000) {
    toastr.warning(message, {timeOut: timeOut});
  }

  info(message: string, timeOut: number = 5000) {
    toastr.message(message, {timeOut: timeOut});
  }
}
