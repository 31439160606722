import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class VideoGalleryItemsService extends DataService {

  constructor(httpClient: HttpClient, private authService: AuthService, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/Video-Gallery-Items', httpClient);
  }

  getAllGalleries() {
    return this.httpClient.get(this.baseUrl + 'api/Video-Gallery-Items/all')
      .pipe(catchError(this.handleError))
  }

  postFile(files: FileList, videoId: number) {
    const formData: FormData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files', files.item(index), files.item(index).name);
    }
    let token = this.authService.getToken;
    return this.httpClient
      .post(this.baseUrl + 'api/Video-Gallery-Items?videoId=' + videoId, formData, { reportProgress: true, observe: 'events' })
      .pipe(catchError(this.handleError))
  }

}