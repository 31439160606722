import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.css']
})
export class UserBarComponent implements OnInit {

  email: string = '';
  _authService;
  constructor(private authService: AuthService) {
    this._authService = authService;
    this.email = this.authService.currentUser.sub;
  }

  ngOnInit(): void {
  }

}
