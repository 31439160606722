import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SuccessStoryService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/success-stories', httpClient);
  }

  getFeatured() {
    return this.httpClient.get(this.baseUrl + 'api/success-stories/featured')
      .pipe(catchError(this.handleError))
  }

  getAllSuccessStories() {
    return this.httpClient.get(this.baseUrl + 'api/success-stories/all')
      .pipe(catchError(this.handleError))
  }


}